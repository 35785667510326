/* https://tailwindcss-v0.netlify.app/docs/colors */
:root {
    --blue-lightest: #EFF8FF;
    --blue-lighter: #BCDEFA;
    --blue-light: #6CB2EB;
    --blue-base: #3490DC;
    --blue-dark: #2779BD;
    --blue-darker: #1C3D5A;
    --blue-darkerest: #12283A;

    --green-base: #38C172;
    --green-dark: #1F9D55;

    --red-light: #EF5753;
    --red-base: #E3342F;
    --red-dark: #CC1F1A;
    --red-darker: #621B18;

    --grey-lightest: #F8FAFC;
    --grey-lighter: #F1F5F8;
    --grey-light: #DAE1E7;
    --grey-base: #B8C2CC;
    --grey-dark: #8795A1;
    --grey-darker: #606F7B;
    --grey-darkest: #3D4852;
    --grey-black: #22292F;
}

/* animace */
.animated {
    animation-duration: 1.25s;
}
@keyframes slideInLeft {
    from { transform:translate3d(-100%,0,0); visibility:visible }
    to { transform:translate3d(0,0,0) }
}
.slideInLeft {
    animation-name:slideInLeft
}
@keyframes slideInRight {
    from { transform: translate3d(100%,0,0); visibility:visible }
    to { transform: translate3d(0,0,0) }
}
.slideInRight {
    animation-name:slideInRight
}
@keyframes slideInUp {
    from { transform: translate3d(0,100%,0); visibility:visible }
    to { transform:translate3d(0,0,0) }
}
.slideInUp {
    animation-name: slideInUp;
}
@keyframes fadeInDown {
    from { opacity:0;transform:translate3d(0,-100%,0) }
    to { opacity:1;transform:none}
}
.fadeInDown{animation-name:fadeInDown}
@keyframes fadeInLeft {
    from { opacity:0;transform:translate3d(-100%,0,0) }
    to { opacity:1;transform:none}
}
.fadeInLeft{animation-name:fadeInLeft}
@keyframes fadeInRight {
    from { opacity:0;transform:translate3d(100%,0,0) }
    to { opacity:1;transform:none}
}
.fadeInRight{animation-name:fadeInRight}
@keyframes fadeInUp {
    from { opacity:0;transform:translate3d(0,100%,0) }
    to { opacity:1;transform:none}
}
.fadeInUp{animation-name:fadeInUp}

.elementor-background-overlay {
    background-color: #072955;
    opacity: 0.65;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.z-0 {
    z-index: 0;
}
.z-1 {
    z-index: 1;
}

/* označení povinného pole ve formuláři */
label.required {
    font-weight: bold;
}
label.required::after {
    content: " *";
}

/* ikonky v alertu */
.alert {
    align-items: center; /* kvůli vertikálnímu zarovnání ikony a textu */
    display: flex;
}
.alert i {
    font-size: 150%;
    margin-right: 10px;
}
.alert i + span {
    margin-right: 25px;
}
/* barvy odkazů */
a {
    color: #3490dc;
    text-decoration: none;
    background-color: transparent;
}
a:hover {
    color: #1d68a7;
    text-decoration: underline;
}
a.btn:hover {
    text-decoration: none;
}
/* barva čáry */
hr {
    color: #DAE1E7; /* grey light */
    opacity: 1;
}
/* vložení &nbsp - díky tomu nebude mít prázdný "dd" jinou velikost než "dd" obsahující text */
dd::after {
    content: '\00a0';
}
/* formulář - větší odsazení mezi řádky */
.form-group {
    margin-bottom: 1rem;
}
/* bootstrap - změna barvy muted textu */
.text-muted {
    color: #8795A1 !important; /* grey dark */
}
/* bootstrap - škaredý okraj u tlačítek, na které se kliklo */
.btn {
    box-shadow: none !important;
}
/* bootstrap - podtrhávání odkazů u link tlačítek */
.btn-link {
    text-decoration: none;
}
.btn-link:hover {
    text-decoration: underline;
}
/* větší odsazení kolem ikon v tlačítku */
.btn {
    white-space: nowrap;
}
.btn i ~ span,
.dx-button .dx-button-content i ~ span {
    margin-left: 0.5rem;
}
.dx-button.btn .dx-button-content {
    padding: 0;
}.dx-button:not(.btn) .dx-button-content {
    padding: 4px;
}
.dx-button-has-icon .dx-button-content {
    display: flex;
}
.nav-item .dx-button-has-icon .dx-button-content {
    display: inline-block;
}
.btn .badge {
    margin-left: 0.5rem;
}
/* položka v rozbalovacím menu bez kulatého okraje */
.btn.dropdown-item {
    border-radius: 0;
}
/* barva ikon v tlačítku */
.btn.btn-outline-primary .dx-icon {
    color: #3490DC; /* blue base */
}
.btn.btn-outline-primary:hover .dx-icon {
    color: #fff;
}
/* aby byl text stejně velký jako u bootstrapího .col-form-label */
.dx-popup-content,
.dx-radiobutton,
.dx-checkbox {
    font-size: 16px;
}

/* dxLookup s přidaným tlačítkem na mazání hodnoty - napozicování tlačítka na konec lookupu */
.lookup-show-clear-button,
.tagbox-show-clear-button {
    position: relative;
}
.lookup-show-clear-button .dx-button,
.tagbox-show-clear-button .dx-button {
    border: none;
    position: absolute;
    right: 40px;
    top: 4px;
}
.tagbox-show-clear-button .dx-button {
    right: 10px;
}
.lookup-show-clear-button .dx-button.dx-state-hover,
.tagbox-show-clear-button .dx-button.dx-state-hover {
    background-color: transparent;
}
.lookup-show-clear-button .dx-icon-clear,
.tagbox-show-clear-button .dx-icon-clear {
    color: #99a1a8; /* dx barva */
}

/* barva nadpisu v kalendáři */
.dx-calendar-navigator .dx-calendar-caption-button.dx-button .dx-button-content {
    color: #212529; /* bootstrap body color */
}
/* barva tlačítek v kalendáři */
.dx-calendar-navigator-previous-month.dx-button .dx-icon,
.dx-calendar-navigator-previous-view.dx-button .dx-icon {
    color: #3490DC; /* blue base */
}
.dx-calendar-cell.dx-calendar-selected-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-datetoday,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-contoured-date.dx-calendar-today,
.dx-calendar-cell.dx-calendar-selected-date.dx-calendar-today
{
    -webkit-box-shadow: inset 0 0 0 1000px #3490DC; /* blue base */
    box-shadow: inset 0 0 0 1000px #3490DC; /* blue base */
}

/* aby se nad disabled dxButton ukazoval hint (title) */
.add-hint,
.dx-button,
.btn {  
    pointer-events: auto;  
}
/* šedé pozadí pro dx alerty */
.dx-overlay-shader {
    background-color: rgba(0,0,0,.4);
}

/* velikost textu v listech stejná jako v bootstrapu */
.dx-list.dx-widget,
.dx-widget {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    -webkit-text-size-adjust: 100%;
}

/* aby byla ikona zarovnaná s textem na tlačítku */
.dx-button-has-text .dx-icon {
    margin-bottom: 2px;
}
/* dxList - barvy */
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected,
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused,
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active {
    background-color: #BCDEFA !important; /*blue lighter */
    color: #22292F !important;
}
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover:not(.dx-list-item-selected):not(.dx-state-focused) {
    background-color: #EFF8FF !important; /*blue lightest*/
}
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover {
    background-color: #6CB2EB !important; /*blue light*/
    color: #22292F !important;
}
/* dxList - obyčejný kurzor nad řádkem, na který se nedá klikat */
.dx-list.no-pointer .dx-list-item {
    cursor: default;
}

/* datagrid - při pohybu myší nad řádkem změnit kurzor */
.dx-widget:not(.no-pointer) .dx-data-row:not(.no-pointer).dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row) td:not(.dx-focused) {
    cursor: pointer;
}
/* datagrid - barva řádku při přejetí myši */
.dx-datagrid-table .dx-data-row.dx-state-hover td {
    background-color: #EFF8FF !important; /*blue lightest*/
}
/* datagrid - barva neklikacích řádků při přejetí myši */
.dx-datagrid-table .dx-data-row.dx-state-hover.no-pointer td {
    background-color: #fff !important;
}
/* datagrid - nad editovatelnou buňkou změnit kurzor */
.dx-datagrid-table .dx-data-row td.editable {
    cursor: pointer;
}
/* datagrid - změna barvy editované buňky */
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-cell-modified::after,
.dx-datagrid-rowsview .dx-data-row .dx-cell-modified.dx-datagrid-invalid::after {
    border-color: #38C172; /* green base */
}

/* aby tlačítko ve sloupci buttons bylo přes celou šířku buňky a dobře se tak na něj klikalo */
.dx-datagrid .dx-command-edit-with-icons a {
    width: 100% !important;
}

/* datagrid - odstranění zbytečného prázdného místa */
.dx-datagrid .dx-freespace-row {
    display: none !important;
}

/* datagrid - zvýraznění ikonky aktivního filtru */
.dx-datagrid .dx-header-filter {
    color: #e3342f;
}
.dx-datagrid .dx-header-filter.dx-header-filter-empty {
    color: #8795A1;
}

/* datagrid - tmavší záhlaví */
.dx-datagrid-headers {
    color: #212529;
    font-weight: bold;
}
.dx-datagrid-table .dx-header-row {
    background-color: #DAE1E7; /*grey light*/
}
/* datagrid - mazaný řádek zvýraznit červeně (i pokud je nad ním kurzor) */
.dx-datagrid-rowsview .dx-row-removed > td,
.dx-datagrid-rowsview .dx-row-removed.dx-state-hover > td {
    background-color: #FCEBEA !important; /* red lightest */
    color: #000;
}

/* dx komponenty - stejný okraj jako u bootstrap nadpisů */
.dx-datagrid,
.dx-scheduler {
    margin-bottom: 1rem;
}

/* dx-textbox - světlejší barva pro okraj aktivního pole */
.dx-texteditor.dx-state-active.dx-editor-filled, .dx-texteditor.dx-state-active.dx-editor-outlined, .dx-texteditor.dx-state-active.dx-editor-underlined, .dx-texteditor.dx-state-focused.dx-editor-filled, .dx-texteditor.dx-state-focused.dx-editor-outlined, .dx-texteditor.dx-state-focused.dx-editor-underlined {
    border-color: #6CB2EB; /*blue ligth*/
}
/* dx-checkbox - světlejší barva pro okraj aktivního pole */
.dx-checkbox.dx-state-hover .dx-checkbox-icon {
    border-color: #6CB2EB; /*blue ligth*/
}
/* aby měl textbox stejné orámování jako Sekce - viz Section.css */
.dx-textbox.border {
    border-color: #008DD2 !important;
    border-radius: 0.375rem;
}

/* správná barva ikony vložené do dx tlačítka */
.btn-primary .dx-icon {
    color: #fff;
}

/* aby rozměry dropdown tlačítka byly stejné, jako u ostatních tlačítek */
.dx-dropdownbutton.btn {
    padding-left: 0;
    padding-right: 0;
}
.dx-dropdownbutton.btn .dx-button {
    border-color: transparent;
    /* color: #fff !important; Aby nemělo dropdown tlačítko bílý text, který splývá s pozadím */
}

/* dxFileUploader - aby nebyl vertikálně rozházený (jinde label, jinde tlačítko, jinde text "nebo přetáhněte soubor sem */
.dx-fileuploader-input-wrapper {
padding: 0;
}
.dx-fileuploader-input-wrapper .dx-button {
float: none;
}
.dx-fileuploader-input-label {
padding-left: 0;
}
/* zbytečně velké mezery kolem komponenty DxFileUploader */
.dx-fileuploader-wrapper,
.dx-fileuploader-files-container {
    padding: 0;
}

/* načítací kolečko */
.dx-loadindicator * {
    display: none;
}
.dx-loadindicator {
    border: 3px solid #BCDEFA !important; /* blue lighter */
    border-radius: 50% !important;
    border-top: 3px solid #2779BD !important; /* blue dark */
    width: 2.5em;
    height: 2.5em;
    animation: rotate-loading 1.5s linear infinite;
}
@keyframes rotate-loading {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* mazací ikony v dxList */
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item .dx-icon, .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active .dx-button .dx-icon, .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-focused .dx-button .dx-icon {
    color: #3490DC;
}
.dx-list .dx-button-mode-contained,
.dx-list .dx-button-mode-contained.dx-state-hover {
    color: #3490DC;
    border-color: #3490DC;
}
/* aby mazací tlačítko v dxList vypadlo stejně jako btn-sm */
.dx-button-has-icon.dx-list-static-delete-button {
border-radius: 0.25rem;
}
.dx-button-has-icon.dx-list-static-delete-button .dx-button-content{
padding: 0.35rem 0.45rem;
line-height: 1.5;
}
.dx-button-has-icon.dx-list-static-delete-button .dx-icon {
font-size: 0.875rem;
}
.dx-list-static-delete-button.dx-button-mode-contained.dx-state-hover {
background-color: #008DD2;
}
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-hover .dx-list-static-delete-button .dx-icon {
color: #008DD2 !important;
}
.dx-list:not(.dx-list-select-decorator-enabled) .dx-list-static-delete-button.dx-button-mode-contained.dx-state-hover .dx-icon {
color: #fff !important;
}


/* barvy alertů */
.alert {
    border: none;
}
.alert-success {
    background-color: var(--color-chrome-green-lightest);
    color: var(--color-chrome-green-darkest);
}
.alert-success i {
    color: var(--color-chrome-green-darkest);
}
.alert-danger {
    background-color: var(--color-chrome-red-lightest);
    color: var(--color-chrome-red-darkest);
}
.alert-danger i {
    color: var(--color-chrome-red-darkest);
}
.alert-warning {
    background-color: #FCFBEB; /* yellow lightest */
    color: #453411; /* yellow darkest */
}
.alert-warning i {
    color: #F2D024; /* yellow dark */
}
.alert-info {
    background-color: var(--color-chrome-blue-lightest);
    color: var(--color-chrome-blue-darkest);
    background-color: #EFFBFF; /* elementor */
    color: #072955;
}
.alert-info i {
    color: var(--color-chrome-blue-darkest);
}
.alert-secondary {
    background-color: #F1F5F8; /* grey lighter */
    color: #3D4852; /* grey darkest */
}
.alert-secondary i {
    border-color: #8795A1; /* grey dark */
}

/* accordion */
.accordion-button:not(.collapsed) {
    color: #212529;
    background-color: #fff;
    box-shadow: none;
}
.accordion-button:focus {
    border: none;
    box-shadow: none;
}

/* barevný sweet alert toast */
.colored-toast.swal2-icon-success {
    background-color: #1F9D55 !important; /* green dark */
    color: #fff;
}

.colored-toast.swal2-icon-error {
    background-color: #f44336 !important; /* green dark */
    color: #fff;
}

/* bootstrap barvy tlačítek */
.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #3490DC;
    --bs-btn-border-color: #3490DC;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #2779BD;
    --bs-btn-hover-border-color: #2779BD;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #2779BD;
    --bs-btn-active-border-color: #2779BD;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #3490DC;
    --bs-btn-disabled-border-color: #3490DC;
}
.btn-outline-primary {
    --bs-btn-color: #3490DC;
    --bs-btn-border-color: #3490DC;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #3490DC;
    --bs-btn-hover-border-color: #3490DC;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #3490DC;
    --bs-btn-active-border-color: #3490DC;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #3D4852; /* grey darkest */
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #606F7B; /* grey darker */
    --bs-gradient: none;
}

:root {
    /* https://developer.chrome.com/ */
    --color-chrome-yellow-lightest: rgba(254, 247, 224, 0.3);
    --color-chrome-yellow-darkest: #de7100;
    --color-chrome-red-lightest: rgba(252, 232, 230, 0.4);
    --color-chrome-red-darkest: #9f0e0e;
    --color-chrome-blue-lightest: rgba(232, 240, 254, 0.4);
    --color-chrome-blue-darkest: #174ea6;
    --color-chrome-green-lightest: rgba(230, 244, 234, 0.4);
    --color-chrome-green-darkest: #0d652d;
}
