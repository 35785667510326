/* sekce ve stránce */
.section {
    background-color: #fff;
    border-radius: 0.375rem; /* viz bootstrap .btn */
    margin-bottom: 1.5rem;
}
.section.border {
    padding: 1rem;
}
/* 
.section:not(#breadcrumbs) {
    border: 1px solid #B8C2CC;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
*/
.section.border {
    border-color: #008DD2 !important;
}

.section:empty {
    height: 500px;
}

/* hlavička sekce */
.section .head {
    align-items: center;
    display: flex;
    margin-bottom: 0.5rem;
    width: 100%;
}

.section .head h2 i {
    padding: .5rem;
    border-radius: 0.375rem; /* viz bootstrap .btn */
    color: #fff;
    background-image: linear-gradient(135deg, #6C94BC 0%, #008DD2 100%);
}
.section .head h2 i.fa-fw {
    font-weight: 300;
    width: 2em;
}

/* hlavička sekce - akční tlačítka */
.section .head .actions  {
    margin-left: 1rem;
    flex-grow: 1;
    display: flex;
    align-items: stretch;
}
.section .divider  {
    border-left: 1px solid #DAE1E7;
    margin: 0 1rem;
}
.section .head .actions .dx-button,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button {
    border: none;
    color: #3490DC; /* blue base */
    margin: 0 0.5rem;
}
.section .head .actions .dx-button .dx-icon,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button .dx-icon {
    display: block;
    margin: 0 auto;
    margin-bottom: 5px;
    color: #3490DC; /* blue base */
}
.section .head .actions .dx-button .dx-button-content,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button .dx-button-content {
    display: block;
}
.section .head .actions .dx-button .dx-button-content span,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button .dx-button-content span {
    margin: 0 auto;
}
.section .head .actions .dx-button.dx-state-focused,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button.dx-state-focused {
    background-color: inherit;
}
.section .head .actions .dx-button.dx-state-hover,
.section .head .actions .dx-button.dx-state-hover .dx-icon,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button.dx-state-hover,
.dx-popup-title .dx-toolbar-before .dx-toolbar-button .dx-button.dx-state-hover .dx-icon {
    text-decoration: none;
    color: #E3342F; /* red base */
    background-color: inherit;
}
/* doprava zarovnané odkazy */
.section.actions-right .head .actions  {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 0.5rem;
}

.section .head .dropdowns {
    flex-grow: 1;
    text-align: right;
    margin-bottom: .5rem; /* h2 */
    line-height: 1.2;
}
.transformation {
    transition: transform 0.1s linear;
    will-change: transform;
}

@media only screen and (max-width: 768px) {
    /* hlavička sekce */
   .section .pdfEdit {
       position: sticky;
       display: flex;
        top: 75px;
        left: 0;
        right: 0;
        z-index: 1000;
        background: #fff;
        padding: 10px;
        padding-left: 0;
    }

    #fullscreen .section .pdfEdit {
        position: sticky;
        top: 58px;
        left: 0;
        right: 0;
        z-index: 1000;
        background: #fff;
        padding: 10px;
        padding-left: 0;
    }
}